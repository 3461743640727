<template>
  <div id="baiDuMap"></div>
</template>
<script>
  const BMap = window.BMap;
  const moduleName_startPoint = "users";
  const moduleName_endPoint = "useUnits";

  let map;
  let start;
  let end;
  let driving;
  let zoom;
  export default {
    components: {},
    props: {
      startPointId: {
        type: Number,
        default: 0,
      },
      endPointId: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        startPointX: "",
        startPointY: "",
        endPointX: "",
        endPointY: "",
      };
    },
    mounted() {
      this.getAll();
      console.log("startPointId............." + this.startPointId);
      console.log("endPointId............." + this.endPointId);
    },
    methods: {
      createMap() {
        // 初始化地图
        map = new BMap.Map("baiDuMap");
        // 初始化中心位置(两点的中心)
        zoom = 14;
        map.centerAndZoom(
          new BMap.Point((this.startPointX + this.endPointX) * 1.000000 / 2, (this.startPointY + this.endPointY) * 1.000000 / 2), zoom);
        // 起始位置
        start = new BMap.Point(this.startPointX, this.startPointY);
        // 终点位置
        end = new BMap.Point(this.endPointX, this.endPointY);
        // 初始化路线规划组件
        driving = new BMap.DrivingRoute(map, {
          renderOptions: {
            map: map,
            autoViewport: true,
          },
          onPolylinesSet: (routes) => {
            let searchRoute = routes[0].getPolyline(); // 导航路线
            map.addOverlay(searchRoute);
          },
        });
        // 查询路线
        if (this.startPointX !== "" && this.startPointY !== "" && this.endPointX !== "" && this.endPointY !== "") {
          driving.search(start, end);
        }
        // 修改起始点图标
        let alarmIcon = new BMap.Icon("static/images/baiDuMap/alarm.png", new BMap.Size(60, 60));
        let gongIcon = new BMap.Icon("static/images/baiDuMap/gong.png", new BMap.Size(60, 60));
        driving.setMarkersSetCallback((result) => {
          result[0].marker.setIcon(gongIcon);
          result[1].marker.setIcon(alarmIcon);
        });
        // 地图控件
        map.addControl(new BMap.MapTypeControl({}));
        let navigationControl = new BMap.NavigationControl({
          anchor: window.BMAP_ANCHOR_TOP_LEFT,
          type: window.BMAP_NAVIGATION_CONTROL_LARGE,
        });
        map.addControl(navigationControl);
        map.enableScrollWheelZoom(true);
        map.addEventListener("zoomend", function() {
          zoom = this.getZoom();
        });
        this.keepSearch();
      },
      getAll() {
        this.$axios.all([this.getAlarmPeoplePoint(), this.getUseUnitPoint()]).then(this.$axios.spread((res1, res2) => {
          console.log(res1);
          let data1 = res1.data;
          if (data1 !== "") {
            this.startPointX = data1.longitude;
            this.startPointY = data1.latitude;
          }
          console.log("startPointX............." + this.startPointX);
          console.log("startPointY............." + this.startPointY);

          let data2 = res2.data;
          this.endPointX = data2.lng;
          this.endPointY = data2.lat;
          console.log("endPointX............." + this.endPointX);
          console.log("endPointY............." + this.endPointY);

          // 两个请求现在都执行完成
          if (this.startPointX === "" || this.startPointY === "" || this.startPointX === null || this.startPointY === null ||
            this.startPointX === undefined || this.startPointY === undefined) {
            this.startPointX = this.endPointX;
            this.startPointY = this.endPointY;
          }
          this.createMap();
        }));
      },
      getAlarmPeoplePoint() {
        return this.$api.getData(moduleName_startPoint + "/" + this.startPointId + "/location");
      },
      getUseUnitPoint() {
        return this.$api.getById(moduleName_endPoint, this.endPointId);
      },
      keepSearch() {
        window.setInterval(() => {
          this.$axios.all([this.getAlarmPeoplePoint()]).then(this.$axios.spread((res) => {
            let data1 = res.data;
            if (data1 !== "") {
              if (this.startPointX !== data1.longitude || this.startPointY !== data1.latitude) {
                this.startPointX = data1.longitude;
                this.startPointY = data1.latitude;
                console.log("startPointX............." + this.startPointX);
                console.log("startPointY............." + this.startPointY);
                // 起始位置
                start = new BMap.Point(this.startPointX, this.startPointY);
                // 终点位置
                end = new BMap.Point(this.endPointX, this.endPointY);
                // 查询路线
                if (this.startPointX !== "" && this.startPointY !== "" && this.endPointX !== "" && this.endPointY !== "") {
                  driving.search(start, end);
                }
              }
            }
          }));
        }, 5000);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
